#NavigationBar_Main{
    /*border-bottom: grey;*/
    /*border-bottom: 0.1px solid #8c8888;*/
    display: flex;
    align-items: center;
    /*position: absolute;*/
    width: 100%;
    top: 0px;
    height: 8vmin;
    position: fixed;
    background-color: white;
    z-index: 99;
    box-shadow: 0 2px 8px -1px #383636;
    min-height: 30px;
    max-height: 100px;

    /*filter: drop-shadow(0px 0px 10px #383636);*/

    /*background-color: blue;*/

}
#NavigationBar_Main a {
    text-decoration: none;
    color: #000000;
}
#NavigationBar_Left img{
    height: 8vmin;
    min-height: 25px;
}
#NavigationBar_Right img{
    min-height: 20px;
    height: 5vmin;
}
#NavigationBar_Spacer{
    flex: 5;
}
#NavigationBar_Main h3{
    padding: 0;
    /*margin: 10px;*/
}
#NavigationBar_Left{
    font-size: max(12px, 3vmin);
    display: flex;
    align-items: center;
    margin-left: 40px;
    /*margin: 10px;z*/
}
#NavigationBar_Buttons{
    font-size: max(12px, 3vmin);
    display: flex;
    align-items: center;
    /*margin-left: 40px;*/
    margin: 30px;
}
#NavigationBar_Buttons a{
    padding: 10px;
}
#NavigationBar_Right{
    margin-right: 40px;
}
#NavigationBar_Right a{
    margin: 5px;
/*    color: black;*/
/*    text-decoration: none;*/
/*    margin-right: 40px;*/
}