
#Packs_Left {
    text-align: left;
    vertical-align: middle;
    align-items: center;
    /*background-color: blue;*/
    display: flex;
    flex: 4;
    min-width: var(--imageMinWidth);

}
#Left_Text {
    vertical-align: middle;
    padding: 30px;
    padding-left: 50px;
    /*background-color: red;*/
}
#Packs_Right {
    background-color: var(--accentPurple);
    flex: 5;
    min-width: calc(5/4 * var(--imageMinWidth));

}
#Img_Container{
    width: 15vw;
    /*height: 10px;*/
    /*background-color: red;*/
    padding-bottom: 30px;
}
#Packs_Main {
    display: flex;
    flex-wrap: wrap;
    /*height: 100%;*/
    /*background-color: red;*/
}

#Packs_Main h1{
    font-size: 3vw;
    font-weight: bold;
}
h2{
    font-size: 2vw;
    font-weight: normal;
    padding-bottom: 10%;;
}
#Left_Text h2 h3 h4{
    font-weight: normal;
}
.accent{
    color: var(--accentPurple);
    background-color: transparent;
}