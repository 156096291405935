/*.App {*/
/*  text-align: center;*/
/*}*/

:root {
  --accentPurple: #93a1dd;
  --imageMinWidth: 200px;
}
* {
  font-family: 'Noto Sans', serif;

}

#content{
  padding-top: max(30px, min(100px, 8vmin));
}