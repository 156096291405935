#FooterBar_Main{
    /*border-bottom: grey;*/
    /*border-top: 0.1px solid #8c8888;*/
    display: flex;
    /*font-size: 15px;*/
    align-items: center;
    /*position: absolute;*/
    width: 100%;
    bottom: 0px;
    /*height: 80px;*/
    background-color: #FAF9F6;
    height: 8vmin;
    font-size: 1.5vw;

    /*background-color: blue;*/

}
#FooterBar_Spacer{
    flex: 2;
}
#FooterBar_Right a{
    color: black;
    padding: 10px;
}
#FooterBar_Left{
    padding-left: 5vw;
}
#FooterBar_Right{
    padding-right: 5vw;
}