#FeaturesPage_Main{
    display: flex;
    flex-wrap: wrap;
    align-self: stretch;

}
#FeaturesPage_Left{
    flex: 5;
    min-width: calc(5/4 * var(--imageMinWidth));

    background: linear-gradient(90deg, white 80%, var(--accentPurple) 20%);
    display: flex;
}
#FeaturesPage_Right{
    flex: 4;
    min-width: var(--imageMinWidth);

}
#Right_Text{
    padding: 40px;
}
#FeaturesPage_Main h3{
    font-size: 2vw;
}
#FeaturesPage_Main li{
    font-size: 1.7vw;
    margin-bottom: 2px;
}
#iPad_Img{
    align-self: center;
    /*box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);;*/
    filter: drop-shadow(0px 0px 10px #383636);

    width: 100%;
}